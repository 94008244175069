body {
  background: #eaecef;
}

.react-select-container {
  .location {
    font-size: 12px;
    line-height: 12px;
  }

  .react-select__menu {
    z-index: 1003;

    .react-select__option--is-focused {
      .text-muted {
        color: #787c85 !important;
      }
    }

    .react-select__option--is-selected {
      .text-muted {
        color: #d3dbe9 !important;
      }
    }

    .spacer {
      margin-top: 22px;
      margin-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 40, 100, 0.12);
    }

    .extra-options {
      padding: 8px 12px;

      .btn-link:hover {
        text-decoration: none;
      }

      .btn-secondary.disabled,
      .btn-secondary:disabled {
        cursor: not-allowed;
        color: #82888e;
      }
    }
  }
}

.card-title:nth-child(3) {
  font-size: 14px;
}

.card-title.small-title {
  font-size: 10px;
}

.btn-primary {
  background-color: #55a8a9;
  border-color: #55a8a9;

  &:hover {
    background-color: #488f90;
    border-color: #448687;
  }

  &:not(:disabled):not(.disabled):active {
    background-color: #448687;
    border-color: #407e7f;
  }

  &.disabled, &:disabled {
    background-color: #55a8a9;
    border-color: #55a8a9;
    cursor: default;
  }
}

.color-green {
  color: #5eba00;

  line {
    stroke: #5eba00;
    stroke-width: 2px;
  }
}

.color-purple {
  color: #a55eea;
}

.color-orange {
  color: #fd9644;
}

.color-blue {
  color: #467fcf;
}

.color-azure {
  color: #45aaf2;
}

.color-red {
  color: #e74c3c;
}

.color-indigo {
  color: #6574cd;
}

.color-cyan {
  color: #17a2b8;
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #55a8a9;
}

.read-only {
  background: #f8f9fa;
}

.header-brand-img {
  height: 4rem;
}

footer {
  .logo {
    height: 3rem;
  }
}

.page-header {
  align-items: baseline;

  .page-subtitle {
    margin-left: 1rem;
    font-size: 1.25rem;
    display: flex;
  }
}

.loader {
  &.white {
    color: #fff;
  }

  &.sm,
  &.sm::before,
  &.sm::after {
    width: 1.5em;
    height: 1.5em;
  }

  &.sm::before,
  &.sm::after {
    margin: -0.6rem 0 0 -0.65rem;
  }

  &.center {
    margin: auto;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.reports-category {
  width: 180px;
}

.years-to-replace {
  width: 70px;
}

.table {
  th {
    font-size: 0.75rem;
  }

  .w-short {
    width: 70px;
  }

  .w-medium {
    width: 150px;
  }

  .w-long {
    width: 250px;
  }
}

.organization-card {
  min-height: 365px;
}

// Get rid of the up/down arrows for number fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.helper-menu {
  z-index: 1002;
  font-size: 1.1rem;
  margin: 2rem 0 1.5rem;
}

.header {
  .dropdown-menu {
    z-index: 1003;
  }
}

.accelerators-header {
  padding: 0.5rem 1.5rem 0.5rem 0;
  display: flex;
  min-height: 3.5rem;

  .accelerators-title {
    font-size: 1.125rem;
    line-height: 1.2;
    font-weight: 400;
  }
}

.modal-open {
  .modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
  }
}

.modal-dialog.photo {
  max-width: 800px;
}

.reserve-items {
  .content-heading {
    cursor: pointer;
    color: #9aa0ac;
    font-size: 1.1rem;

    &:hover {
      color: #495057;
    }

    &.selected {
      color: #495057;
      font-weight: 600;
    }
  }

  .page-header {
    margin-bottom: 0;
  }

  .card {
    overflow: auto;
  }

  .reserve-items-table {
    display: block;
    max-height: 65vh;

    .form-control {
      border: 0;
      border-radius: 0;
    }

    .thead {
      overflow-y: auto;
      overflow-x: hidden;
      position: sticky;
      z-index: 1000;
      top: 0;
      background: white;
      border-bottom: 2px solid #dee2e6;

      .th {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 400;
        color: #000;
        padding: 0 0 0 0.5rem;

        .resizer {
          min-width: 0.5rem;
          height: 100%;
          border-right: 1px solid #dee2e6;

          &:hover {
            background: lightblue;
          }
        }
      }
    }

    .tbody {
      overflow-y: scroll;
      overflow-x: hidden;
      font-size: 13px;

      select,
      input,
      textarea {
        font-size: 13px;
      }

      .tr {
        border-bottom: 1px solid #dee2e6;

        &:first-child {
          .td {
            padding-top: 0.5rem;
          }
        }

        .td {
          border-right: 1px solid #dee2e6;
          padding: 0.2rem 0.2rem 0 0.2rem;

          &:last-child {
            border-right: 0;
            padding-right: 1rem;
          }

          input {
            &:focus {
              border-color: transparent;
              box-shadow: none;
            }

            &:placeholder-shown {
              font-size: 13px;
            }
          }

          .choose-file {
            position: relative;
            display: inline-block;

            input[type="file"] {
              -webkit-appearance: none;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              width: 100%;
              height: 100%;
            }
          }

          .input-group-prepend {
            font-size: 13px;

            .input-group-text {
              font-size: 13px;
              padding: 0;
              border: 0;
              background-color: transparent;
            }

            & + input {
              padding-left: 0.2rem;
            }
          }

          .photo,
          document {
            cursor: pointer;
          }

          .loader {
            height: 2rem;
            width: 2rem;

            &:before {
              height: 2rem;
              width: 2rem;
            }

            &:after {
              height: 2rem;
              width: 2rem;
            }
          }
        }
      }
    }
  }

  .projected-item-costs {
    max-height: 65vh;

    .card-header {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
    }

    table {
      position: relative;
      border-collapse: separate;
      border-spacing: 0;

      thead {
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 1000;
        border-bottom: 2px solid #dee2e6;

        th {
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          background: white;
          color: #000;
          z-index: 1000;
        }

        th:nth-child(1) {
          background: #fff;
          left: 0;
          position: sticky;
          position: -webkit-sticky;
          z-index: 1001;
        }

        th:last-child {
          background: #fff;
          right: 0;
          position: sticky;
          position: -webkit-sticky;
          z-index: 1001;
        }
      }

      tbody {
        overflow-y: scroll;
        overflow-x: hidden;

        td:nth-child(1) {
          border-right: 2px solid #dee2e6;
          background: #fff;
          left: 0;
          position: sticky;
          position: -webkit-sticky;
          z-index: 999;
        }

        td:last-child {
          border-left: 2px solid #dee2e6;
          background: #fff;
          right: 0;
          position: sticky;
          position: -webkit-sticky;
          z-index: 999;
        }
      }
    }
  }
}

.reserve-items-table {
  .placeholder {
    color: darkgray;
  }

  .choose-file input[type="file"] {
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &.tabulator {
    &.sticky-header {
      overflow: inherit;

      .tabulator-header {
        position: sticky !important;
        position: -webkit-sticky !important;
        top: 0;
        z-index: 1020;
      }
    }

    .tabulator-footer .tabulator-calcs-holder {
      &,
      .tabulator-row {
        background: #f8f9fa !important;
      }

      .tabulator-row {
        border: 1px solid lightgrey;

        .tabulator-cell[tabulator-field="totalCost"] {
          border-right: 1px solid #dee2e6;
          border-left: 1px solid #dee2e6;
        }
      }
    }

    .tabulator-header {
      text-transform: uppercase;
      font-size: 0.6rem;

      .tabulator-col .tabulator-col-content {
        padding: 0.25rem;

        .tabulator-col-title {
          white-space: normal;
          padding-right: 10px;
        }
      }
    }

    &.table-bordered .tabulator-header .tabulator-col:nth-child(2),
    &.table-bordered .tabulator-row .tabulator-cell:nth-child(2) {
      border-right-width: 3px;
    }

    .tabulator-cell {
      font-size: 13px;

      &.tabulator-editing {
        border: 1px solid #1d68cd !important;
      }
    }
  }
}

.accelerators {
  .stamp {
    &.text-large {
      font-size: 3rem;
    }

    &.stamp-lg {
      min-width: 4rem;
      height: 4rem;
      line-height: 4rem;
    }
  }

  .name {
    font-size: 1.1rem;

    &:hover {
      text-decoration: none;
    }
  }
}

.training {
  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    .card {
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;

      &:hover {
        border: 1px solid #467fcf;
      }

      .video {
        .play {
          background: #000 url(training.png) no-repeat center center;

          i {
            font-size: 2rem;
            font-weight: 400;
            color: red;
          }
        }
      }
    }
  }
}

.financial-dashboard {
  .range-and-rates {
    min-height: 294px;
  }

  label,
  select,
  .form-control,
  .table-header-font {
    font-size: 13px;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .table {
    th {
      color: rgb(73, 80, 87);
    }

    td {
      padding: 0;
    }
  }

  .form-control {
    border: 0;
    border-radius: 0;

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .input-group-text {
    padding: 0;
    border: 0;
    background: transparent;
  }

  select {
    text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;

    option {
      text-align: center;
    }

    &.form-control:not([size]):not([multiple]) {
      height: inherit;
    }

    &.custom-select {
      border: 0;
      padding: 0.25rem 1.75rem 0.25rem 0.75rem;
    }
  }

  .baseline-plan-settings,
  .simulation-plan-settings,
  .alternate-plan-settings {
    .table .table {
      background-color: transparent;
    }

    .card {
      min-height: 275px;
      max-height: 275px;
      overflow: auto;
    }

    .card-body {
      font-size: 12.5px;
      padding: 1rem 1rem;
    }

    tr {
      td:first-child,
      th:first-child,
      td:last-child,
      th:last-child {
        padding: 0;
      }
    }

    .form-control {
      padding-left: 0;
      padding-right: 0;
    }

    .read-only {
      min-width: 100px;
      max-width: 120px;
    }
  }

  .alternate-plan-settings {
    .dimmer.active .dimmer-content {
      opacity: 0.2;
    }

    .refresh-error {
      white-space: normal !important;
    }
  }
}

.financial-dashboard-pdf {
  padding: 0.5in;
  color: #000;

  .card {
    border: none;

    small.inflation-rate {
      font-size: 10px;
    }
  }

  .baseline-plan-settings,
  .simulation-plan-settings,
  .alternate-plan-settings {
    max-width: 490px;
  }

  .card.sub-card {
    border: none;
    box-shadow: none;

    .card-header {
      padding-left: 0;
      padding-bottom: 0;
    }

    h3.card-title {
      font-size: 0.7rem;
    }

    .card-table tr th:first-child {
      padding-left: 0.75rem;
    }

    .card-table tr th:last-child {
      padding-right: 0.75rem;
    }

    .card-body {
      padding: 0.25rem;

      .table thead th {
        font-size: 8px;
        color: #000;
      }

      tbody {
        font-size: 11px;

        tr {
          td {
            padding: 0;
          }
        }
      }
    }
  }

  &.web-only {
    padding: 0;

    .card.sub-card {
      h3.card-title {
        font-size: 0.9rem;
      }

      .card-body {
        .table thead th {
          font-size: 12px;
        }

        tbody {
          font-size: 11.5px;
        }

        small {
          font-size: 87.5%;
        }
      }
    }
  }
}

.annual-plan {
  thead {
    tr {
      th {
        font-size: 10px;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 11px;
        padding-left: 0;
        padding-right: 0;
        text-align: center;

        .breakdown {
          font-weight: bold;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.reports {
  .sidenav {
    .active {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .c3 {
    width: 95%;
  }

  path {
    &.c3-line-baseline,
    &.c3-line-alternate,
    &.c3-line-simulation,
    &.c3-line-zero {
      fill-opacity: 0;
    }
  }

  .card-body {
    td {
      font-size: 13px;
    }
  }

  .reports-home {
    margin-top: 65px;

    .lead {
      font-size: 0.9rem;
    }
  }

  .missing-prerequisites-card {
    min-height: 450px;
  }
}

.special-requirements-report-pdf {
  &.pdf {
    & > .card {
      border: 0;
    }

    .table th {
      font-size: 10px;
    }

    .card-body tbody td, input {
      font-size: 10px;
    }

    .special-requirements-report-form {
      .card-table tr td:first-child {
        padding: .5rem 0 .5rem 0;
      }

      td {
        padding: .5rem 0 .5rem 0;
        border: 0;
      }
    }
  }

  &.web-only {
    .table th {
      font-size: 13px;
    }

    .card-body tbody td, input {
      font-size: 13px;
    }

    .percent-increase {
      min-height: 207px;
    }

    .card-title {
      font-size: 1.125rem;
    }
  }

}

.new-organization-reserve-items {
  .custom-switch-indicator {
    width: 3.75rem;
    height: 1.75rem;

    &:before {
      height: calc(1.75rem - 4px);
      width: calc(1.75rem - 4px);
    }
  }

  .custom-switch-input:checked ~ .custom-switch-indicator:before {
    left: calc(2rem + 1px);
  }

  .times {
    &::before {
      content: "\ea00";
      font-family: "feather";
    }
  }
}

.reserve-items-pdf {
  padding: 0.5in 0.5in 0 0.5in;
  color: #000;
  font-size: 0.65rem;

  .card {
    box-shadow: none;
    border: none;
    margin: 0;

    .card-body {
      padding-bottom: 0;
    }
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }

  .tr {
    border-bottom: 1px solid #dee2e6;
    flex-wrap: wrap;
  }

  .thead {
    border-bottom: 2px solid #dee2e6;
    background: white;

    .th {
      text-transform: uppercase;
      font-weight: 400;
      color: #000;
      padding: 0 0 0 0.5rem;
    }
  }

  .tbody {
    .tr {
      &:first-child {
        .td {
          padding-top: 0.5rem;
        }
      }

      .td {
        padding: 0.2rem 0.2rem 0 0.2rem;

        &:last-child {
          border-right: 0;
          padding-right: 1rem;
        }
      }
    }
  }

  &.web-only {
    font-size: 12px;
    padding: 0;

    .card {
      margin-bottom: 1.5rem;
    }

    .thead {
      .th {
        font-size: 10px;
      }
    }
  }
}

.glossary {
  .no-stripe {
    border: 0;
    background: transparent;
  }

  .table-striped tbody tr:nth-of-type(even) {
    &.no-stripe {
      border: 0;
      background: transparent;
    }
  }
}

.glossary-pdf {
  padding: 0.5in 0.5in 0 0.5in;
  font-size: 12px;
  color: #000;

  .card {
    box-shadow: none;
    border: none;
    margin: 0;

    .table-bordered {
      border: 0;
      border-top: 1px solid #dee2e6;
    }

    .card-body {
      padding-bottom: 0;
    }
  }
}

.report-pdf {
  padding: 0.5in 0.5in 0 0.5in;
  color: #000;

  .card {
    margin: 0;
    font-feature-settings: "liga" 0;
    border: none;
    box-shadow: none;
    font-size: 12px;

    .card-body {
      padding-bottom: 0;
    }

    .tr {
      border-bottom: 1px solid #dee2e6;
    }

    .thead {
      border-bottom: 2px solid #dee2e6;
      background: white;

      .th {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-weight: 400;
        color: #000;
        padding: 0 0 0 0.5rem;
      }
    }

    .tbody {
      .tr {
        &:first-child {
          .td {
            padding-top: 0.5rem;
          }
        }

        .td {
          border-right: 1px solid #dee2e6;
          padding: 0.2rem 0.2rem 0 0.2rem;

          &:last-child {
            border-right: 0;
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .annual-plan {
    .thead {
      .th {
        font-size: 10px;
      }
    }

    .tbody {
      .tr {
        .td {
          font-size: 10px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }

  .percent-funded {
    padding: 0;
  }

  &.web-only {
    padding: 0;
  }
}

.funding-summary-pdf {
  color: #000;

  .table td {
    padding: 0;
  }
}

.photo-album-pdf {
  color: #000;

  .card {
    border: 0;
  }

  &.web-only {
    .card {
      border: 1px solid rgba(0, 40, 100, 0.12)
    }
  }

  img {
    max-height: 250px;
  }

  .card.photo {
    padding: 0;
    border: 0;

    .card-header {
      padding: 0;
      border: 0;
    }

    .card-body {
      padding: 0;
      text-align: center;
    }
  }
}

.settings {
  .card {
    min-height: 296px;
  }

  .logo-upload {
    .fe {
      font-size: 3rem;
    }

    min-height: 130px;

    .dropzone {
      &:focus {
        border-color: #2196f3;
      }

      max-height: 130px;
      outline: none;
      transition: border .24s ease-in-out;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
    }

    .logo {
      max-height: 130px;

      img {
        max-height: 130px
      }
    }
  }

  .paid-invoices {
    td {
      font-size: 12px;
    }
  }

  .add-collaborators {
    table {
      tbody {
        td {
          font-size: 13px;
        }
      }
    }
  }
}

.activate-subscription {
  .fixed-price {
    small {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1.1;
    }
  }

  input {
    padding: 3.5px 12px;
    font-size: 16px;
  }

  .StripeElement--focus {
    border-color: #1991eb;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
  }

  input::placeholder {
    color: darkgray;
    font-size: 16px;
    font-weight: 500;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  }

  .StripeElement--invalid + .invalid-feedback {
    display: block;
  }
}

.change-log-pdf {
  .table td {
    padding: 0;
  }

  .removed {
    text-decoration: line-through;
  }

  img.removed {
    border: 2px solid #cd201f;
  }

  img.added {
    border: 2px solid #5eba00;
  }

  &.pdf {
    & > .card {
      border: 0;
    }

    h3 {
      font-size: 0.75rem;
    }

    .table th, .table td {
      font-size: 10px;
    }
  }
}

.parm-report-pdf {
  &.pdf {
    & > .card {
      border: 0;

      .card-title {
        font-size: 0.75rem;
      }
    }

    padding: 0.5in 0.5in 0 0.5in;
    font-size: 12px;

    .sub-card {
      .card-title {
        font-size: 1rem;
      }
    }
  }
}

.card-title {
  small {
    font-size: 12px;
  }
}